import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';

import { Button, Heading, IconClose, NamedLink } from '../../components';
import css from './AuthenticationPage.module.css';
import LogoYellowImage from './images/logo_yellow.png';

const EmailVerificationInfo = props => {
  const {
    name,
    email,
    onResendVerificationEmail,
    resendErrorMessage,
    sendVerificationEmailInProgress,
  } = props;

  const maskEmail = () => {
    // console.log(email)
    if (!email) return "";
    const [user, domain] = email.split('@');
    return user[0] + '*'.repeat(user.length - 1) + "@" + domain;
  }

  const resendEmailLink = (
    <Button onClick={onResendVerificationEmail}>
      <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
    </Button>
  );

  const fixEmailLink = (
    <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
      <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
    </NamedLink>
  );

  return (
    <div className={css.emailModal}>
      {/* <NamedLink className={css.verifyClose} name="ProfileSettingsPage"> */}
      {/* <span className={css.closeText}>
          <FormattedMessage id="AuthenticationPage.verifyEmailClose" />
        </span> */}
      {/* <IconClose rootClassName={css.closeIcon} /> */}
      {/* </NamedLink> */}

      <img className={css.logo} src={LogoYellowImage} />

      {/* <IconEmailSent className={css.modalIcon} /> */}
      <Heading as="h1" rootClassName={css.emailModalTitle}>
        <FormattedMessage id="AuthenticationPage.verifyEmailTitle" />
      </Heading>
      <p className={css.emailModalHello}>
        <FormattedMessage id="AuthenticationPage.verifyEmailHello" values={{ name }} />
      </p>

      <p className={css.emailModalHello}>
        <FormattedMessage id="AuthenticationPage.verifyEmailInfo" values={{ email: maskEmail() }} />
      </p>
      {resendErrorMessage}

      <div className={css.emailModalLine} />

      <div className={css.bottomWrapper}>
        <p className={css.modalHelperText}>
          {sendVerificationEmailInProgress ? (
            <FormattedMessage id="AuthenticationPage.sendingEmail" />
          ) : (
            resendEmailLink
            // <FormattedMessage id="AuthenticationPage.resendEmail" values={{ resendEmailLink }} />

          )}
        </p>
        {/* <p className={css.modalHelperText}>
          <FormattedMessage id="AuthenticationPage.fixEmail" values={{ fixEmailLink }} />
        </p> */}
      </div>
    </div>
  );
};

export default EmailVerificationInfo;
