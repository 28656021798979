import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, NamedLink, FieldCheckbox, FieldCheckboxGroup, Logo } from '../../../components';
import infoIcon from './images/info_icon.png'
import clientIcon from './images/client_icon.png'
import consultantIcon from './images/consultant_icon.png'
import backArrowIcon from './images/back_arrow.png'
import css from './SignupAccountTypeForm.module.css';
import { accountTypes } from '../../../util/accountTypes';

const SignupAccountTypeFormComponent = props => {
  const { intl, handleOptionSelect } = props;

  const selectAccountTypeLabel = intl.formatMessage({
    id: 'SignupAccountTypeForm.selectAccountTypeLabel',
  });

  const infoTypeLabel = intl.formatMessage({
    id: 'SignupAccountTypeForm.info',
  });

  const clientInfoLabel = intl.formatMessage({
    id: 'SignupAccountTypeForm.clientInfo',
  });

  const consultantInfoLabel = intl.formatMessage({
    id: 'SignupAccountTypeForm.consultantInfo',
  });

  return (
    <div className={css.root}>

      <div className={css.headerWrapper}>
        <NamedLink name={'LoginPage'}>
          <img className={css.headerLeft} src={backArrowIcon} />
        </NamedLink>
        <Logo className={css.headerCentered} isDark={true} />
      </div>
      <p>{selectAccountTypeLabel}</p>

      <div className={css.horizontalWrapper}>
        <img className={css.icon} src={infoIcon} />
        <p className={css.info}>{infoTypeLabel}</p>
      </div>

      <div className={css.optionWrapper}
        onClick={() => handleOptionSelect(accountTypes.client)}
      >
        <img className={css.optionIcon} src={clientIcon} />
        <p className={css.optionInfo}>{clientInfoLabel}</p>
      </div>

      <div className={css.optionWrapper}
        onClick={() => handleOptionSelect(accountTypes.consultant)}
      >
        <img className={css.optionIcon} src={consultantIcon} />
        <p className={css.optionInfo}>{consultantInfoLabel}</p>
      </div>

    </div>
  );
}

SignupAccountTypeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
};

const { string, bool } = PropTypes;

SignupAccountTypeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  intl: intlShape.isRequired,
};

const SignupAccountTypeForm = compose(injectIntl)(SignupAccountTypeFormComponent);
SignupAccountTypeForm.displayName = 'SignupAccountTypeForm';

export default SignupAccountTypeForm;
