import React from 'react';

// This is Facebook's logo, you are not allowed to change its color
export const FacebookLogo = (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" transform="translate(0 0.115723)" fill="white" />
    <path
      d="M10.3685 19.4491V12.5671H8.66663V10.0892H10.3685V7.97278C10.3685 6.30968 11.3738 4.78241 13.6902 4.78241C14.628 4.78241 15.3215 4.87855 15.3215 4.87855L15.2669 7.19245C15.2669 7.19245 14.5596 7.18509 13.7878 7.18509C12.9525 7.18509 12.8187 7.59671 12.8187 8.2799V10.0892H15.3333L15.2239 12.5671H12.8187V19.4491H10.3685Z"
      fill="#013957"
    />
    <path
      d="M10.3685 19.4491V12.5671H8.66663V10.0892H10.3685V7.97278C10.3685 6.30968 11.3738 4.78241 13.6902 4.78241C14.628 4.78241 15.3215 4.87855 15.3215 4.87855L15.2669 7.19245C15.2669 7.19245 14.5596 7.18509 13.7878 7.18509C12.9525 7.18509 12.8187 7.59671 12.8187 8.2799V10.0892H15.3333L15.2239 12.5671H12.8187V19.4491H10.3685Z"
      fill="black"
      fillOpacity="0.2"
    />
  </svg>
);

// This is Google's logo, you are not allowed to change its color
export const GoogleLogo = (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" transform="translate(0.5 0.115723)" fill="white" />
    <g clipPath="url(#clip0_3908_31958)">
      <path
        d="M18.9533 12.2685C18.9533 11.7918 18.9106 11.3335 18.8311 10.8935H12.5V13.4938H16.1178C15.9619 14.334 15.4883 15.046 14.7764 15.5227V17.2093H16.9489C18.22 16.039 18.9533 14.3157 18.9533 12.2685Z"
        fill="#013957"
      />
      <path
        d="M18.9533 12.2685C18.9533 11.7918 18.9106 11.3335 18.8311 10.8935H12.5V13.4938H16.1178C15.9619 14.334 15.4883 15.046 14.7764 15.5227V17.2093H16.9489C18.22 16.039 18.9533 14.3157 18.9533 12.2685Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M12.5 18.8379C14.315 18.8379 15.8367 18.236 16.9489 17.2093L14.7764 15.5227C14.1745 15.926 13.4045 16.1643 12.5 16.1643C10.7492 16.1643 9.26724 14.9818 8.73863 13.3929H6.4928V15.1346C7.59891 17.3316 9.87224 18.8379 12.5 18.8379Z"
        fill="#013957"
      />
      <path
        d="M12.5 18.8379C14.315 18.8379 15.8367 18.236 16.9489 17.2093L14.7764 15.5227C14.1745 15.926 13.4045 16.1643 12.5 16.1643C10.7492 16.1643 9.26724 14.9818 8.73863 13.3929H6.4928V15.1346C7.59891 17.3316 9.87224 18.8379 12.5 18.8379Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M8.73854 13.393C8.6041 12.9896 8.52771 12.5588 8.52771 12.1158C8.52771 11.6727 8.6041 11.2419 8.73854 10.8385V9.09686H6.49271C6.02215 10.0336 5.7773 11.0675 5.77771 12.1158C5.77771 13.2005 6.03743 14.2271 6.49271 15.1346L8.73854 13.393Z"
        fill="#013957"
      />
      <path
        d="M8.73854 13.393C8.6041 12.9896 8.52771 12.5588 8.52771 12.1158C8.52771 11.6727 8.6041 11.2419 8.73854 10.8385V9.09686H6.49271C6.02215 10.0336 5.7773 11.0675 5.77771 12.1158C5.77771 13.2005 6.03743 14.2271 6.49271 15.1346L8.73854 13.393Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M12.5 8.06711C13.487 8.06711 14.3731 8.40627 15.0697 9.07238L16.9978 7.14433C15.8336 6.0596 14.312 5.39349 12.5 5.39349C9.87224 5.39349 7.59891 6.89988 6.4928 9.09683L8.73863 10.8385C9.26724 9.24961 10.7492 8.06711 12.5 8.06711Z"
        fill="#013957"
      />
      <path
        d="M12.5 8.06711C13.487 8.06711 14.3731 8.40627 15.0697 9.07238L16.9978 7.14433C15.8336 6.0596 14.312 5.39349 12.5 5.39349C9.87224 5.39349 7.59891 6.89988 6.4928 9.09683L8.73863 10.8385C9.26724 9.24961 10.7492 8.06711 12.5 8.06711Z"
        fill="black"
        fillOpacity="0.2"
      />
    </g>
    <defs>
      <clipPath id="clip0_3908_31958">
        <rect
          width="14.6667"
          height="14.6667"
          fill="white"
          transform="translate(5.16663 4.78241)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const LinkedInLogo = (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" transform="translate(0.5 0.115723)" fill="white" />
    <path
      d="M8.49607 19.4491V9.5535H5.35212V19.4491H8.49607ZM6.9245 8.20162C8.02086 8.20162 8.70328 7.44175 8.70328 6.49217C8.68286 5.52118 8.0209 4.78241 6.94531 4.78241C5.86989 4.78241 5.16663 5.5212 5.16663 6.49217C5.16663 7.4418 5.84888 8.20162 6.90398 8.20162H6.9244H6.9245ZM10.2362 19.4491H13.3802V13.9229C13.3802 13.6271 13.4006 13.3317 13.4837 13.1203C13.7109 12.5294 14.2282 11.9174 15.0967 11.9174C16.2344 11.9174 16.6895 12.8248 16.6895 14.1551V19.449H19.8333V13.775C19.8333 10.7354 18.2822 9.32118 16.2137 9.32118C14.5177 9.32118 13.773 10.313 13.3593 10.9885H13.3803V9.5533H10.2363C10.2776 10.4818 10.2363 19.4489 10.2363 19.4489L10.2362 19.4491Z"
      fill="#013957"
    />
    <path
      d="M8.49607 19.4491V9.5535H5.35212V19.4491H8.49607ZM6.9245 8.20162C8.02086 8.20162 8.70328 7.44175 8.70328 6.49217C8.68286 5.52118 8.0209 4.78241 6.94531 4.78241C5.86989 4.78241 5.16663 5.5212 5.16663 6.49217C5.16663 7.4418 5.84888 8.20162 6.90398 8.20162H6.9244H6.9245ZM10.2362 19.4491H13.3802V13.9229C13.3802 13.6271 13.4006 13.3317 13.4837 13.1203C13.7109 12.5294 14.2282 11.9174 15.0967 11.9174C16.2344 11.9174 16.6895 12.8248 16.6895 14.1551V19.449H19.8333V13.775C19.8333 10.7354 18.2822 9.32118 16.2137 9.32118C14.5177 9.32118 13.773 10.313 13.3593 10.9885H13.3803V9.5533H10.2363C10.2776 10.4818 10.2363 19.4489 10.2363 19.4489L10.2362 19.4491Z"
      fill="black"
      fillOpacity="0.2"
    />
  </svg>
);
